import React from 'react';
import CyberpunkLine from '../assets/images/CyberpunkLineBrief.png'; 
import '../assets/styles/BriefSection.css';

function BriefSection() {
  return (
    <section className="brief-section" id="about">
      <div className="container">
        <img src={CyberpunkLine} alt="Cyberpunk Line" className="line-brief-1"/>
        <img src={CyberpunkLine} alt="Cyberpunk Line" className="line-brief-2"/>
        <h2 className="brief-section-title">ORIGINÁRIOS</h2>
        <p className="brief-section-text">
          Summer day originários vem para mostrar resistência e nos lembrar dos nossos povos originários, aqueles que se fazem resistência por todo canto do nosso país. 
          Honramos os povos originários, cuja a sabedoria ancestral guia nossos passos e corações, junto ao brilho do sol e sob o véu da noite e suas estrelas, coisas magicas acontecem. A batida da musica é a pulsação da terra, e a dança é nossa celebração do presente!
        </p>
        <a href="https://www.ingressonacional.com.br/evento/25808/summer-day" className="brief-section-button" target="_blank" rel="noopener noreferrer">INGRESSOS</a>
      </div>
    </section>
  );
}

export default BriefSection;
