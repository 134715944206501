import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../assets/styles/WhatsappIcon.css';

const WhatsappIcon = () => {
  const handleClick = () => {
    const phoneNumber = '554598205629';
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-icon" onClick={handleClick}>
      <FontAwesomeIcon icon={faWhatsapp} size="3x" />
    </div>
  );
};

export default WhatsappIcon;
