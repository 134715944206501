import React, { useState, useEffect } from 'react';
import '../assets/styles/DJsSection.css';
import DJCard1 from '../assets/images/DJCard1.png';
import DJCard2 from '../assets/images/DJCard2.png';
import DJCard3 from '../assets/images/DJCard3.png';
import DJCard4 from '../assets/images/DJCard4.png';
import DJCard5 from '../assets/images/DJCard5.png';
import DJCard6 from '../assets/images/DJCard6.png';
import DJCard7 from '../assets/images/DJCard7.png';
import DJCard8 from '../assets/images/DJCard8.png';
import DJCard9 from '../assets/images/DJCard9.png';
import DJCard10 from '../assets/images/DJCard10.png';
import DJCard11 from '../assets/images/DJCard11.png';
import DJCard12 from '../assets/images/DJCard12.png';
import DJCard13 from '../assets/images/DJCard13.png';

const DJ_IMAGES = [
  DJCard1,
  DJCard2,
  DJCard3,
  DJCard4,
  DJCard5,
  DJCard6,
  DJCard7,
  DJCard8,
  DJCard9,
  DJCard10,
  DJCard11,
  DJCard12,
  DJCard13,
];

function DJsSection() {
  const [djProfile1, setDjProfile1] = useState(0);
  const [djProfile2, setDjProfile2] = useState(1);
  const [djProfile3, setDjProfile3] = useState(2);
  const [djProfile4, setDjProfile4] = useState(3);

  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsChanging(true);

      setTimeout(() => {
        setDjProfile1((prevIndex) => (prevIndex + 4) % DJ_IMAGES.length);
        setDjProfile2((prevIndex) => (prevIndex + 4) % DJ_IMAGES.length);
        setDjProfile3((prevIndex) => (prevIndex + 4) % DJ_IMAGES.length);
        setDjProfile4((prevIndex) => (prevIndex + 4) % DJ_IMAGES.length);

        setIsChanging(false);
      }, 500); 
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const getProfileClassName = (profileNumber) => {
    let className = `dj-profile dj-profile-${profileNumber}`;
    if (isChanging) {
      className += ' dj-profile-fade-out';
    }
    return className;
  };

  return (
    <section className="djs-section" id='attractions'>
      <div className="container">
        <div className="djs-info">
          <h3 className="djs-info-subtitle">QUAIS NOMES VÃO TOCAR?</h3>
          <h2 className="djs-info-title">40 DJ'S CONFIRMADOS</h2>
        </div>
        <div className="djs-grid">
          <div className={getProfileClassName(1)}>
            <img src={DJ_IMAGES[djProfile1]} alt="DJ Profile 1" />
          </div>
          <div className={getProfileClassName(2)}>
            <img src={DJ_IMAGES[djProfile2]} alt="DJ Profile 2" />
          </div>
          <div className={getProfileClassName(3)}>
            <img src={DJ_IMAGES[djProfile3]} alt="DJ Profile 3" />
          </div>
          <div className={getProfileClassName(4)}>
            <img src={DJ_IMAGES[djProfile4]} alt="DJ Profile 4" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DJsSection;
