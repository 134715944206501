// src/components/Footer.js
import React from 'react';
import '../assets/styles/Footer.css';
import Logo from '../assets/images/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="footer-contact">
          <p>Contato: calabresi@summerday.com.br</p>
        </div>
        <div className="footer-social">
          <a href="https://www.instagram.com/summerdayevents" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.facebook.com/summ3r.d4y" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
        </div>
      </div>
      <hr />
      <div className="footer-rights">
        <p>Todos os direitos reservados para Summer Day</p>
      </div>
    </footer>
  );
}

export default Footer;
