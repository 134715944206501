import React from 'react';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import BriefSection from './components/BriefSection';
import DJsSection from './components/DJsSection';
import TextSection from './components/TextSection';
import Footer from './components/Footer';
import FormSection from './components/FormSection';
import ThankYouSection from './components/ThankYouSection';
import WhatsAppIcon from './components/WhatsappIcon';
import ReactPixel from 'react-facebook-pixel';
import PageViewTracker from './components/PageViewTracker';
import ExclusiveStageSection from './components/ExclusiveStageSection';
import NeonCarouselSection from './components/NeonCarouselSection';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
 React.useEffect(() => {
    ReactPixel.init('713123243764458');
  }, []);
  return (
    <Router>
      <div className="App">
        <WhatsAppIcon />
        <Routes>
          <Route path="/" element={
            <>
              <PageViewTracker />
              <Header />
              <HeroSection />
              <BriefSection />
              <DJsSection />
              <TextSection />
              <ExclusiveStageSection />
              <NeonCarouselSection />
              <FormSection />
            </>
          } />
          <Route path="/obrigado" element={
            <>
            <PageViewTracker />
            <ThankYouSection />
            </>
          } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
