import React from 'react';
import '../assets/styles/ExclusiveStageSection.css';

function ExclusiveStageSection() {
    return (
        <section className="exclusive-stage-section">
            <div className="container">
            <h1 className="exclusive-stage-title">
                <svg className="neon-effect-stage" width="100%" height="100px" viewBox="0 0 800 100" xmlns="http://www.w3.org/2000/svg">
                    <text x="32%" y="50%" dy=".35em" className="neon-text-stage fill" text-anchor="middle">
                        TENDA E
                    </text>
                    <text x="71%" y="50%" dy=".35em" className="neon-text-stage stroke" text-anchor="middle">
                        PALCO
                    </text>
                </svg>
                <p className="stage-subtitle">EXCLUSIVOS DA SUMMER DAY ORIGINÁRIOS</p>
            </h1>
            </div>
        </section>
    );
}

export default ExclusiveStageSection;
