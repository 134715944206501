import React from 'react';
import CyberpunkLine from '../assets/images/CyberpunkLine.png';
import Logo from '../assets/images/Logo.png';
import '../assets/styles/Header.css';

function Header() {
  return (
    <header className="header">
      <div class="container">
        <nav className="navigation">
          <img className="logo" src={Logo}></img>
          <a className="item" href="#about">SOBRE</a>
          <a className="item" href="#attractions">ATRAÇÕES</a>
          <a className="item" href="#contact">CONTATO</a>
          <a href="https://www.ingressonacional.com.br/evento/25808/summer-day" className="ticket-button" target="_blank" rel="noopener noreferrer">INGRESSOS</a>
        </nav>
        <img src={CyberpunkLine} alt="Cyberpunk Line" className="line-header" />
      </div>
    </header>
  );
}

export default Header;
