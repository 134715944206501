import React from 'react';
import '../assets/styles/HeroSection.css';

function HeroSection() {
  const [showScrollIndicator, setShowScrollIndicator] = React.useState(true);
  const [opacity, setOpacity] = React.useState(1);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight * 0.1;

      if (scrollY > windowHeight) {
        setShowScrollIndicator(false);
        setOpacity(0); 
      } else {
        setShowScrollIndicator(true);
        setOpacity(1 - scrollY / windowHeight); 
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className="hero-section">
      <div class="container">
          <div className="event-info">
            <h1 className="event-title">
                <svg className="neon-effect" width="100%" height="100px" viewBox="0 0 800 100" xmlns="http://www.w3.org/2000/svg">
                    <text x="0%" y="50%" dy=".35em" className="neon-text fill" text-anchor="start">
                        SUMMER
                    </text>
                    <text x="93%" y="50%" dy=".35em" className="neon-text stroke" text-anchor="end">
                        DAY
                    </text>
                </svg>
            </h1>
            <p className="event-subtitle">GARANTA JÁ O SEU INGRESSO</p>
            <p className="event-date">30, 31/12 E 01/01/2024</p>
          </div>
          {showScrollIndicator && (
            <div class="scroll-down-indicator" style={{ opacity }}>
            <div class="scroll-text">Deslize para baixo</div>
              <span class="arrow"></span>
            </div>
          )}
        </div>
    </section>
  );
}

export default HeroSection;
