import React from 'react';
import '../assets/styles/TextSection.css';

function TextSection() {
  return (
    <section className="text-section" id="about">
      <div className="container">
      <h2 className="text-section-title">UM FESTIVAL DE TRÊS DIAS</h2>
        <p className="text-section-text">
            Com muita música e cultura, proporcionando uma nova experiência para todos. 
        </p>
      </div>
    </section>
  );
}

export default TextSection;
