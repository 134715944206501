import React from 'react';
import '../assets/styles/ThankYouSection.css';

function ThankYouSection() {
  return (
    <section className="thankyou-section">
      <div className='container'>
        <h2 className='thankyou-section-title'>OBRIGADO POR ENTRAR EM CONTATO!</h2>
        <h3 className='thankyou-section-text'>Seu formulário foi enviado com sucesso. Nós entraremos em contato em breve.</h3>
      </div>
    </section>
  );
}

export default ThankYouSection;
