import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import ReactPixel from 'react-facebook-pixel';
import '../assets/styles/FormSection.css';

function FormSection() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nome: '',
    celular: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const isFormValid = () => {
    return formData.nome && formData.celular && formData.email;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      alert("Por favor, preencha todos os campos.");
      return;
    }

    const userConfirmed = window.confirm("Deseja enviar o formulário?");
    if (!userConfirmed) {
      return;
    }

    emailjs.send('service_ai45za3', 'template_b19577q', { from_name: formData.nome, reply_to: formData.email, celular: formData.celular,}, 'TjYHxfHTdEK-wGBk9')
      .then((response) => {
        ReactPixel.track('CompleteRegistration', {
          content_name: 'Formulario de Contato',
          status: true,
          formData: formData
        });
        alert('Email enviado com sucesso.');
        console.log('Email sent successfully:', response.text);
        navigate('/obrigado');
      }, (error) => {
        alert('Erro ao enviar email.');
        console.log('Failed to send email:', error.text);
      });
  };

  return (
    <section className="form-section" id='contact'>
      <div className="container">
        <h3 className="form-section-subtitle">FALE COM A GENTE</h3>
        <h2 className="form-section-title">CONTATE-NOS</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nome">Nome *</label>
            <input type="text" id="nome" name="nome" value={formData.nome} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="celular">Celular *</label>
            <input type="tel" id="celular" name="celular" value={formData.celular} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail *</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
          </div>
          <button className='form-button' type="submit">Enviar</button>
        </form>
        <div className="card">
          <p className="card-content">
            Confira as informações e lembre-se de preencher todos os dados corretamente.
            Os campos marcados com * são obrigatórios. Fique atento(a) ao preenchimento
            do seu e-mail, pois é através dele que entraremos em contato.
          </p>
        </div>
      </div>
    </section>
  );
}

export default FormSection;
