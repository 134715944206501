import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const PageViewTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactPixel.pageView();
  }, [location]);

  return null; 
};

export default PageViewTracker;