import React from 'react';
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/styles/NeonCarouselSection.css'; 
import PracaDeAlimentacao from '../assets/images/PracaDeAlimentacao.png';
import CabeleleiraDeTrancas from '../assets/images/CabeleleiraDeTrancas.png';
import ArtesanatosVariados from '../assets/images/ArtesanatosVariados.png';
import ArtesanatosIndigenas from '../assets/images/ArtesanatosIndigenas.png';
import AreaParaMassagem from '../assets/images/AreaParaMassagem.png';
import AreaDeDescanso from '../assets/images/AreaDeDescanso.png';
import LojaDeIncensos from '../assets/images/LojaDeIncensos.png';
import FlashTattos from '../assets/images/FlashTattos.png';
import Tabacaria from '../assets/images/Tabacaria.png';

const carouselItems = [
  {
    text: 'Praça de alimentação completa',
    imageUrl: PracaDeAlimentacao,
  },
  {
    text: 'Cabeleleira de tranças',
    imageUrl: CabeleleiraDeTrancas,
  },
  {
    text: 'Artesanatos variados',
    imageUrl: ArtesanatosVariados,
  },
  {
    text: 'Artesanatos indígenas',
    imageUrl: ArtesanatosIndigenas,
  },
  {
    text: 'Área para massagem',
    imageUrl: AreaParaMassagem,
  },
  {
    text: 'Área de descanso',
    imageUrl: AreaDeDescanso,
  },
  {
    text: 'Loja de incensos',
    imageUrl: LojaDeIncensos,
  },
  {
    text: 'Flash Tattos',
    imageUrl: FlashTattos,
  },
  {
    text: 'Tabacaria',
    imageUrl: Tabacaria,
  },
];

function NeonCarouselSection() {
  const settings = {
    centerMode: true, 
    centerPadding: '20px', 
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px', 
        },
      },
    ],
  };

  return (
    <section className="neon-carousel-section">
      <div className="carousel-container">
        <Slider {...settings}>
          {carouselItems.map((item, index) => (
            <div key={index} className="carousel-item">
              <img src={item.imageUrl} alt={item.text} className="carousel-image" />
              <h3 className="carousel-text">{item.text}</h3>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default NeonCarouselSection;
